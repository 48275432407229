<template>
  <div class="pay">
    <div class="pay-container">
      <div class="pay-title">订单信息</div>
      <div class="pay-detail">
        <div class="pay-detail__left">
          <h3>{{ course.courseName }}</h3>
          <p>
            {{ $filters.fmtDate(course.starTime, "yyyy-MM-dd") || "暂无" }}-{{
              $filters.fmtDate(course.endTime, "yyyy-MM-dd") || "暂无"
            }}
          </p>
          <img :src="course.courseCover" alt="课程封面" />
        </div>
        <div class="pay-detail__right">
          ¥<span>{{ course.commodityPrice }}</span>
        </div>
      </div>
      <div class="pay-title">支付方式</div>
      <div class="pay-way">
        <div class="pay-way__item" v-for="item in payWayList" :key="item.value">
          <img :src="item.img" />
          <span class="pay-way-label">{{ item.label }}</span>
          <a-radio
            v-model:checked="item.checked"
            :value="item.value"
            @click="(e) => emit('change', { e, item })"
          ></a-radio>
        </div>
      </div>
      <div class="pay-submit">
        <div @click="handlePay">去支付</div>
      </div>
    </div>
  </div>
  <a-modal
    wrapClassName="phone-modal"
    v-model:visible="state.visible"
    @cancel="closeLogin"
    title="绑定手机号"
    :footer="null"
    centered
    width="350px"
  >
    <BindPhone :visible="state.visible" @success="bindPhoneSuccess"></BindPhone>
  </a-modal>
</template>

<script setup>
import { defineProps, defineEmits, inject, reactive } from "vue";
import { getStore, setStore } from "@/utils/auth";
import { useRouter, useRoute } from "vue-router";
import { createOrder } from "@/api/order";
import { message } from "ant-design-vue";
import BindPhone from "@/components/BindPhone.vue";

const props = defineProps(["course", "payWayList"]);
const emit = defineEmits(["submit", "change"]);
const $bus = inject("$bus");
const router = useRouter();
const route = useRoute();

const state = reactive({
  visible: false,
  userInfo: null,
});

const handlePay = () => {
  // 判断是否登录
  const isLogin = getStore({ name: "isLogin" }) || false;
  if (!isLogin) {
    $bus.emit("showLogin");
    return;
  }
  // 判断是否绑定手机号
  state.userInfo = getStore({ name: "userInfo" });
  if (!state.userInfo.phone) {
    state.visible = true;
    return;
  }
  const target = props.payWayList.find((way) => way.checked);
  createOrder({
    id: route.query.id,
    payType: target.value,
    pageUrl: window.location.href,
  }).then((res) => {
    if (res.code === 200) {
      if (res.data.status !== 1) {
        setStore({
          name: "order",
          content: res.data,
        });
        router.push({
          path: `/payByQrcode`,
          query: {
            id: route.query.id,
            payType: target.value,
            orderId: res.data.id,
            sn: res.data.sn,
          },
        });
      } else {
        router.push({
          path: `/detail`,
          query: {
            id: res.data.courseId,
          },
        });
      }
    } else {
      message.error(res.msg);
    }
  });
};

const bindPhoneSuccess = (params) => {
  state.visible = false;
  setStore({
    name: "userInfo",
    content: {
      ...state.userInfo,
      ...params,
    },
  });
  handlePay();
};
</script>

<style lang="less" scoped>
.pay {
  flex: 1;
  display: flex;
  justify-content: center;
  .pay-container {
    width: 900px;
  }
  .pay-title {
    margin-top: 60px;
    font-size: 18px;
    font-weight: 800;
    margin-bottom: 18px;
  }
  .pay-detail {
    display: flex;
    align-items: flex-end;
    box-shadow: 0px 0px 10px #e3e3e3;
    padding: 30px 25px;
    border-left: 4px solid #fddb00;
    border-radius: 4px;
    &__left {
      flex: 1;
      h3 {
        font-weight: 500;
        font-size: 16px;
      }
      p {
        color: #666;
      }
      img {
        width: 200px;
      }
    }
    &__right {
      width: 100px;
      color: #ff4c4c;
      span {
        font-size: 28px;
        margin-left: 10px;
        font-weight: 500;
      }
    }
  }
  .pay-submit {
    margin-top: 40px;
    display: flex;
    justify-content: center;
    > div {
      display: inline-block;
      background-color: #ffc525;
      color: #fff;
      padding: 6px 40px;
      font-size: 16px;
      border-radius: 30px;
      box-shadow: 0 10px 20px 0 rgba(251, 214, 125, 0.8);
      cursor: pointer;
    }
  }
  .pay-way {
    display: flex;
    &__item {
      margin-right: 30px;
      img {
        width: 50px;
      }
      .pay-way-label {
        margin: 0 10px;
      }
      display: flex;
      align-items: center;
    }
  }
}
</style>
