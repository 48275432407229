<template>
  <div class="common-body">
    <div class="common-container">
      <div class="personal">
        <div class="personal__menu">
          <p
            v-for="item in state.menu"
            :class="[
              'personal__menu-item',
              state.activeKey === item.id && 'active',
            ]"
            :key="item.id"
            @click="changeTabs(item)"
          >
            {{ item.name }}
          </p>
        </div>
        <div class="personal__course">
          <div v-if="state.activeKey === 1" class="info">
            <a-form
              :model="state.formState"
              name="basic"
              :label-col="{ span: 4 }"
              :wrapper-col="{ span: 10 }"
              autocomplete="off"
              @finish="onFinish"
            >
              <a-form-item label="头像" name="avatarId">
                <a-upload
                  name="file"
                  list-type="picture-card"
                  class="avatar-uploader"
                  :show-upload-list="false"
                  :action="state.actionUrl"
                  :headers="state.headers"
                  :data="state.params"
                  :before-upload="beforeUpload"
                  @change="handleChange"
                >
                  <img
                    class="personal-avatar"
                    v-if="state.formState.previewUrl"
                    :src="state.formState.previewUrl"
                    alt="avatar"
                  />
                  <div v-else>
                    <div class="ant-upload-text">Upload</div>
                  </div>
                </a-upload>
              </a-form-item>

              <a-form-item
                label="昵称"
                name="nickName"
                :rules="[{ required: true, message: '请输入昵称' }]"
              >
                <a-input
                  class="input"
                  v-model:value="state.formState.nickName"
                />
              </a-form-item>

              <a-form-item :wrapper-col="{ offset: 4, span: 10 }">
                <a-button class="submit" type="primary" html-type="submit"
                  >保存</a-button
                >
              </a-form-item>
            </a-form>
          </div>
          <div
            v-else-if="state.activeKey === 2"
            v-loading="state.pageLoading"
            :class="{'card-box': true, 'card-box--single': state.courseList.length === 1}"
          >
            <div v-show="!state.pageLoading" class="card-box">
              <Empty v-if="state.courseList.length === 0"></Empty>
              <template v-else>
                <div
                  class="card"
                  v-for="item in state.courseList"
                  :key="item.id"
                >
                  <div class="card__title">{{ item.courseName }}</div>
                  <div class="card__subtitle">{{ item.courseBrief }}</div>
                  <div class="card__footer">
                    <div class="card__teacher">
                      <div
                        class="teacher-item"
                        v-for="teacher in item.userVos"
                        :key="teacher.id"
                      >
                        <img
                          :src="
                            teacher.courseAvatar ||
                            '//static.yanzhishi.cn/images/wechat/user.jpg'
                          "
                          alt="头像"
                        />
                        <p class="text-center">{{ teacher.nickName }}</p>
                      </div>
                    </div>
                    <div class="personal-btn" @click="study(item)">去学习</div>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <OrderList :data="state.orderPayList" v-else></OrderList>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, inject } from "vue";
import { useRouter } from "vue-router";
import { getCourseList } from "@/api/course";
import { setStore, getToken, getTenantCode } from "@/utils/auth";
import { getUserInfo } from "@/api/login";
import { updateUserInfo } from "@/api/user";
import { message } from "ant-design-vue";
import { getBase64 } from "@/utils/tool";
import { notification } from "ant-design-vue";
import Empty from "@/components/Empty";
import OrderList from "./OrderList";

const router = useRouter();
const $bus = inject("$bus");

const state = reactive({
  menu: [
    {
      id: 2,
      name: "我的课程",
    },
    {
      id: 1,
      name: "我的资料",
    },
    {
      id: 3,
      name: "我的订单",
    },
  ],
  courseList: [],
  activeKey: 2,
  formState: {},
  userInfo: {
    nickName: "",
    avatarId: "",
  },
  headers: {
    Authorization: "Bearer " + getToken(),
    "Tenant-Code": 1,
  },
  params: {
    busiType: "1",
  },
  actionUrl: `${process.env.VUE_APP_URL_ORIGIN}/api/user/v1/attachment/upload/`,
  pageLoading: true,
  orderPayList: [],
  // actionUrl: window.location.origin + "http://test.yanzhishi.cn/api/user/v1/attachment/upload/",
});

const queryCourseList = () => {
  state.pageLoading = true;
  getCourseList({
    type: 1,
    scene: "joined",
  }).then((res) => {
    state.courseList = res.filter(item => [1, 2].includes(item.tenantCode));
    state.pageLoading = false;
  });
};

const handleChange = (info) => {
  if (info.file.status === "done") {
    state.formState.avatarId = info.file.response.data.id;
    state.formState.previewUrl = info.file.response.data.previewUrl;
  }
};

const beforeUpload = (file) => {
  const isJPG = file.type === "image/jpeg" || file.type === "image/png";
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isJPG) {
    message.error("上传头像图片只能是 jpg/png 格式!");
  }
  if (!isLt2M) {
    message.error("上传头像图片大小不能超过 2MB!");
  }
  return isJPG && isLt2M;
};

const changeTabs = (tab) => {
  state.activeKey = tab.id;
};

const queryUserInfo = () => {
  getUserInfo()
    .then((res) => {
      state.userInfo = res.data;
      state.formState = {
        nickName: res.data.nickName,
        previewUrl: res.data.previewUrl,
      };
      setStore({ name: "isLogin", content: true });
    })
    .catch((err) => {
      if (err.status === 401) {
        setStore({ name: "isLogin", content: false });
      }
    });
};

// 个人资料保存
const onFinish = () => {
  updateUserInfo({
    avatarId: state.formState.avatarId,
    name: state.formState.nickName,
  }).then(() => {
    notification.success({
      message: "小主",
      description: "已经更新成功了",
    });
    $bus.emit("updateUser");
  });
};

const study = (course) => {
  // setStore({ name: "course", content: course });
  let routeUrl = router.resolve({
    path: `/video`,
    query: {
      id: course.id,
    },
  });
  window.open(routeUrl.href, "_blank");
};

onMounted(() => {
  queryCourseList();
  queryUserInfo();
});
</script>

<style lang="less" scoped>
.personal {
  padding-top: 30px;
  display: flex;
  &__menu {
    width: 250px;
    height: 400px;
    flex-shrink: 0;
    background: #fff;
    margin-right: 15px;
    min-height: 300px;
    &-item {
      line-height: 36px;
      font-weight: 800;
      margin: 10px 0;
      text-align: center;
      border-left: 2px solid transparent;
      cursor: pointer;
      &:hover {
        color: #ffc300;
        border-left: 2px solid #ffc300;
        background-color: #fbf9ed;
      }
      &.active {
        color: #ffc300;
        border-left: 2px solid #ffc300;
        background-color: #fbf9ed;
      }
    }
  }
  &__course {
    flex: 1;
    display: flex;
    width: 100%;
  }
}
.card-box {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  &--single {
    align-items: flex-start;
  }
}
.card {
  width: 48%;
  overflow: hidden;
  margin: 0 2% 2% 0;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  &:nth-child(2n) {
    margin-right: 0;
  }
  &__title {
    color: #333;
    font-size: 18px;
    font-weight: 800;
    text-align: left;
  }
  &__subtitle {
    text-align: left;
    margin-top: 12px;
    color: #666;
  }
  &__teacher {
    flex: 1;
    margin-top: 15px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .teacher-item {
      margin-right: 25px;
      margin-bottom: 8px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
      p {
        margin: 0;
        margin-top: 12px;
        color: #555;
      }
    }
  }
  .personal-btn {
    width: 65px;
    background: #ffcc00;
    color: #fff;
    border-radius: 15px;
    padding: 2px 10px;
    cursor: pointer;
    flex-shrink: 0;
  }
  .card__footer {
    display: flex;
    align-items: center;
    width: 100%;
  }
}
.info {
  width: 100%;
  background: #fff;
  padding: 40px 0 20px 0;
  border-radius: 5px;
  .submit {
    width: 100%;
    background: #ffcc00;
    border-radius: 20px;
    border: none;
    margin-top: 60px;
    color: #333;
    font-weight: 500;
  }
}
.personal-avatar {
  width: 100%;
  height: 100%;
}
</style>
