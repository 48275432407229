<template>
  <div class="common-body home">
    <div style="width: 100%">
      <Swiper v-if="state.swiperData.length > 0" :dataList="state.swiperData"></Swiper>
    </div>
    <div class="common-container" v-loading="state.pageLoading">
      <div>
        <a-tabs
          class="no-tab-border capsule"
          v-model:active-key="state.tenantCode"
          @change="changeTenantCode"
        >
          <a-tab-pane
            v-for="item in state.tenantList"
            :key="item.tenantCode"
            :tab="item.name"
          ></a-tab-pane>
        </a-tabs>
        <a-tabs
          class="no-tab-border line"
          v-model:active-key="state.activeKey"
          @change="changeCourseType"
        >
          <a-tab-pane
            v-for="item in state.courseCategory"
            :key="item.id + '-' + item.tenantCode"
            :tab="item.name"
          ></a-tab-pane>
        </a-tabs>
        <div v-show="!state.pageLoading" class="course">
          <div
            v-for="item in state.courseList"
            :key="item.id"
            class="card"
            @click="goToCourse(item)"
          >
            <div class="card__title">{{ item.courseName }}</div>
            <div class="card__subtitle">{{ item.courseBrief }}</div>
            <div class="card__teacher">
              <div
                v-for="teacher in item.userVos"
                :key="teacher.id"
                class="teacher-item"
              >
                <img
                  :src="
                    teacher.courseAvatar ||
                    '//static.yanzhishi.cn/images/wechat/user.jpg'
                  "
                  alt="老师"
                />
                <p class="text-center">{{ teacher.nickName }}</p>
              </div>
            </div>
            <div class="card__footer">
              <div class="left">{{ item.courseTag }}</div>
              <div class="right">
                <p class="virtual-price">
                  <span>¥</span>
                  <span class="num">{{ item.underlinedPrice }}</span>
                </p>
                <div class="really-price">
                  <span>预估到手</span>
                  <span
                    >¥<span class="mr-4">{{ item.commodityPrice }}</span></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="qrcode-contact"><img src="http://static.yanzhishi.cn/images/pc/v2.0.0/wx_qcode.png" alt="22计算机考研"></div>
  </div>
</template>

<script setup>
import { onMounted, reactive, ref } from "vue";
import Swiper from "@/components/Swiper";
import { getHomeCourseList, getBannerList } from "@/api/course";
import { useRouter } from "vue-router";
import { setStore } from "@/utils/auth";
import { COURSE_TYPE } from "@/utils/const";
const router = useRouter();

const state = reactive({
  tenantCode: -1,
  activeKey: "",
  courseCategory: COURSE_TYPE,
  courseList: [],
  pageLoading: true,
  swiperData: []
});

const queryCourseList = () => {
  state.pageLoading = true;
  state.courseList = [];
  getHomeCourseList({
    courseType: state.activeKey.split("-")[0],
    tenantCode:
      state.tenantCode === -1
        ? state.activeKey.split("-")[1]
        : state.tenantCode,
  }).then((res) => {
    state.courseList = res.data?.courseList.sort(
      (a, b) => a.courseSort - b.courseSort
    );
    state.tenantList = res.data?.tenantList;
    state.pageLoading = false;
  });
};

const changeCourseType = (val) => {
  console.log(val, 111);
  state.activeKey = val;
  queryCourseList();
};

const changeTenantCode = (val) => {
  state.tenantCode = val;
  state.activeKey = "";
  switch (val) {
    case -1:
      state.courseCategory = COURSE_TYPE;
      break;
    default:
      state.courseCategory = COURSE_TYPE.filter(
        (item) => item.tenantCode === val
      );
  }
  queryCourseList();
};

const goToCourse = (course) => {
  setStore({ name: "course", content: course });
  router.push({
    path: `/detail`,
    query: {
      id: course.id,
    },
  });
};

onMounted(() => {
  queryCourseList();
  getBannerList().then(res => {
    state.swiperData = res.data.sort((a, b) => a.bannerSort - b.bannerSort)
  })
});
</script>

<style lang="less" scoped>
.home {
  flex-direction: column;
  align-items: center;
}
.course {
  width: 100%;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  .card {
    width: 32%;
    overflow: hidden;
    margin: 0 2% 2% 0;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: space-between;
    &:nth-child(3n) {
      margin-right: 0;
    }
    &__title {
      color: #333;
      font-size: 18px;
      font-weight: 800;
      text-align: left;
    }
    &__subtitle {
      text-align: left;
      margin-top: 12px;
    }
    &__teacher {
      margin-top: 15px;
      display: flex;
      .teacher-item {
        margin-right: 25px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
        p {
          margin: 0;
          margin-top: 12px;
          color: #555;
        }
      }
    }
    .card__footer {
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right {
        display: flex;
        align-items: center;
        p {
          margin: 0;
        }
        .virtual-price {
          color: #ff4c4c;
          font-weight: 800;
          margin-right: 6px;
          .num {
            font-size: 18px;
            margin-left: 2px;
          }
        }
        .really-price {
          border-radius: 10px;
          line-height: 16px;
          background-color: #ff4c4c;
          padding-right: 6px;
          > span {
            display: inline-block;
            line-height: 20px;
            &:first-child {
              color: #ff4c4c;
              font-size: 12px;
              font-weight: 500;
              text-align: center;
              margin-left: -2px;
              padding: 0 14px 0 6px;
              background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAOQAAAA4CAMAAADadaTrAAAAbFBMVEUAAAD/4+P/4+P/6+v/4+P/5ub/4+P/////5OT/5OT/4+P/4uL/4+P/5OT/5eX/5eX/////4+P/4+P/4+P/4+P/5OT/5eX/5+f/4+P/4+P/4+P/4+P/4+P/4+P/4uL/4+P/4+P/5OT/5eX/4uKhdUJtAAAAI3RSTlMA5uwRrgr0Apw1+fDdc08aBdTLwnphKyCEgGhbt6aPjUk8V79CITAAAAFjSURBVGje5dppboMwEIbhGIPNDmUNkKXpd/87Vm2VCjVUNvCH8bw3eGQbRpjTUjrKwjr1Yhys2Esvtzw47a/M/QRHTvmt3EcMGg/HTzU7llO3AjQSo95ofH8Dnapik/EMUolow2n0QSw1rTX2lLbqs2mlkcJD9SUVrdqrFNcRgChWIMmdx2eVdvW5Om+0fj+CbiKwM2qiB/Knxg7ZgnJK2hgDKvPqP7U2yAa08y2MJckxYJYKzMgc1MsdngN+uxmN+tjfOmy6GJERyJcakRnI5xmRIcgXG5E16FeakCnoJ01I6qPAV70Jebi7gA1FJiQcaOKAHDggfQ5IjwMSHQdkxgFZcUCi44C8c0AqyQCJgQMykQyQCDkg8eCAFAUDJOqSARIhByRGDsg4Z4AEMg5IhCUDJGrJAIn0wQAJhC+LSfyviMWS4Q/zChdL7t0cWbhwGbJUlXUz5dXFHfud8M8fUS/1J09Kz0tnLwYKAAAAAElFTkSuQmCC)
                no-repeat center / 100% 100%;
            }
            &:last-child {
              margin-left: -6px;
              font-size: 12px;
              font-weight: 600;
              color: #fff;
              .mr-4 {
                margin-left: 2px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="less">
.no-tab-border {
  &.ant-tabs-top {
    .ant-tabs-nav::before {
      border-bottom: none;
    }
  }
  &.capsule {
    .ant-tabs-tab {
      padding-bottom: 0;
    }
    .ant-tabs-tab-btn {
      border-radius: 13px;
      padding: 2px 0;
    }
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        background: #fec638;
        color: #fff !important;
        padding: 2px 10px 1px 10px;
      }
    }
  }
  &.line {
    position: relative;
    padding-left: 10px;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 15px;
      width: 2px;
      height: 14px;
      background: #fec638;
    }
  }
}
.qrcode-contact {
  position: fixed;
  right: 30px;
  bottom: 30px;
  img {
    width: 100px;
    height: 126px;
  }
}
</style>
