<template>
  <div class="top">
    <div class="banner">
      <header>
        <div class="wrapper clearfix"></div>
      </header>
      <div class="wrapper introduce">
        <!-- <p>— 专供计算机考研芝士 —</p> -->
        <p>上岸模型</p>
        <p>让更多普通院校学生轻松享受普惠、优质的教育资源！</p>
        <!-- <p>研芝士是由北大、人大、中科院、北邮学霸领衔，依托于海量计算机考研知识库、题库，致力于运用<br/>科技手段提升学习体验，让更多普通院校学生享受普惠、优质的考研资源。</p> -->
      </div>
      <div class="banner-gif">
        <div class="circle">
          <p>RDL</p>
          <p>教学模式</p>
        </div>
        <div class="item-text item-three">练</div>
        <div class="item-text item-four">测</div>
        <div class="item-text item-five">评</div>
        <div class="item-text item-six">讲</div>
        <div class="item-text item-seven">推</div>
        <img
          class="item-one"
          src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_1.png"
          alt=""
        />
        <img
          class="item-two"
          src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_7.png"
          alt=""
        />
        <!-- <img class="item-three" src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_2.png" alt=""> -->
        <!-- <img class="item-four" src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_3.png" alt=""> -->
        <!-- <img class="item-five" src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_4.png" alt=""> -->
        <!-- <img class="item-six" src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_5.png" alt=""> -->
        <img
          class="item-line-one"
          src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_6.png"
          alt=""
        />
        <img
          class="item-line-two"
          src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_6.png"
          alt=""
        />
        <img
          class="item-line-three"
          src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_6.png"
          alt=""
        />
        <img
          class="item-line-four"
          src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_6.png"
          alt=""
        />
        <img
          class="item-line-five"
          src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_6.png"
          alt=""
        />
        <img
          class="item-line-six"
          src="http://static.yanzhishi.cn/images/pc/v2.0.0/animation_6.png"
          alt=""
        />
      </div>
      <div class="banner-footer">
        <!-- <div>首创</div> -->
        <div>
          <p>Recorded course</p>
          <p class="chinese">（视频课程）</p>
        </div>
        <div>+</div>
        <div>
          <p>Data</p>
          <p class="chinese">（芝士数据平台）</p>
        </div>
        <div>+</div>
        <div>
          <p>Live course</p>
          <p class="chinese">（直播课程）</p>
        </div>
        <div>=</div>
        <div>研芝士<span class="reg">&reg;</span>RDL教学模式</div>
        <!-- <div class="row">
          <div class="col-xs-4">
            <div class="banner-footer-content">
              <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/knowledge.png" alt="海量考研题库">
              <div class="img-right">
                <h3>海量考研题库</h3>
                <p>覆盖500+所高校考研题目<br>让你考场游刃有余</p>
              </div>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="banner-footer-content">
              <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/efficient.png" alt="真正有效备考">
              <div class="img-right">
                <h3>真正有效备考</h3>
                <p>预学，练习，测评，巩固<br>助你打怪通关</p>
              </div>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="banner-footer-content">
              <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/prefer.png" alt="优选芝士内容">
              <div class="img-right">
                <h3>优选芝士内容</h3>
                <p>书本芝士还不会，这里深入浅出<br>帮你排疑解难</p>
              </div>
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="grey number">
      <div class="bg-earth">
        <div class="wrapper number-area">
          <h2 class="bottom-line">为什么选择研芝士?</h2>
          <div class="row number-content">
            <div class="col-xs-4">
              <p>
                <strong class="city">360</strong>
                <span>个</span>
              </p>
              <p class="num-info">覆盖城市</p>
            </div>
            <div class="col-xs-4">
              <p>
                <strong class="exercise">200,000</strong>
                <span>+</span>
              </p>
              <p class="num-info">计算机领域题目数</p>
            </div>
            <div class="col-xs-4">
              <p>
                <strong class="success">188,458</strong>
                <span>+</span>
              </p>
              <p class="num-info">累计用户数</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="produce" class="online">
      <div class="wrapper">
        <h2 class="bottom-line">贴身陪伴你的备考专家</h2>
        <div class="row applet online-produce">
          <div class="col-xs-5 wow fadeInLeft">
            <h3>研芝士题库Mini App</h3>
            <p>
              基于三审三校的海量计算机学科真题与模拟题题库，为备考学生提供拍照搜题搜答案服务，以及覆盖计算机考研、期末不挂科、计算机等级考试、计算机软件水平考试四大门类共60个考试科目的基于章节考点的刷题服务，帮助考生实现学中练、练中学，让备考更轻松！
            </p>
            <div class="qrcode">
              <button class="practice btn-self">马上体验</button>
              <div class="qrcode-img">
                <img
                  src="http://static.yanzhishi.cn/images/pc/v2.0.0/qrcode.jpg"
                  alt="小程序"
                />
              </div>
            </div>
          </div>
          <div class="col-xs-7 wow fadeInRight">
            <div class="applet-img">
              <img
                class="online-produce-img"
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/xiaochengxu.png"
                alt="小程序"
              />
              <img
                class=""
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/tk.png"
                alt="小图标"
              />
              <img
                class=""
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/pk.png"
                alt="小图标"
              />
              <img
                class=""
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/su.png"
                alt="小图标"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="grey">
      <div class="bbs-bg">
        <div class="wrapper pt-80">
          <div class="row bbs online-produce">
            <div class="col-xs-7 wow fadeInLeft">
              <div class="bbs-img">
                <img class="online-produce-img" src="http://static.yanzhishi.cn/images/pc/v2.0.0/shequ.png" alt="社区">
                <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/hat.png" alt="小图标">
                <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/bi.png" alt="小图标">
                <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/su.png" alt="小图标">
              </div>
            </div>
            <div class="col-xs-5 wow fadeInRight">
              <h3>芝士社区/社群</h3>
              <p>
                择校难？别发愁，这里目前已收集全国261所高校的相关考研信息，分数线、报录比、院校信息、备考指南、经验分享，你备考过程中想看的，统统都有。
                <br>
                教材中的知识点看完不理解？来芝士社区，社区按照备考科目分类，细化到各章节，教材中的易错点、重难点、高频考点统统有大神分享的讲解。如果你看完有些地方还是不理解，别担心！可以在相关文章下和大神咨询互动，针对性帮你解决困扰。
              </p>
              <a href="//bbs.yanzhishi.cn" target="_blank"><button class="btn-self">马上体验</button></a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="offline">
      <div class="wrapper">
        <h3 class="black-title">清北名师&nbsp;&nbsp;精品图书</h3>
        <p class="subtitle">
          大学教育质量参差不齐，学生学习能力各异，清北名师基于十年以上的教学经验，深知学生学习的痛点、难点，以教育公平为目标，打造研芝士系列教研产品！
        </p>
        <div class="row produce">
          <div class="col-xs-6 wow zoomInLeft">
            <div class="card">
              <div class="img-box bg1"></div>
              <div class="book-info book-teach">
                <p>《计算机考研精深解读》教材</p>
                <p class="book-detail">
                  15位大学讲师立足考研大纲，综合研究十年百年真题基础上编订的，以命题者的视角讲解知识点，以考试者的视角透析重难点，精选十年百校真题详细解析，预测、评估当年命题方向和重点，是计算机考研专业课复习的“全能图书”。
                </p>
                <a
                  href="https://detail.tmall.com/item.htm?spm=a1z10.3-b.w4011-24165555638.41.43686772OBL3oX&id=666495195716&rn=f153f7d64c0408e6be1d9da27fe13e54&abbucket=15&sku_properties=14829532:72110507"
                  target="_blank"
                  >前往购买 &gt;</a
                >
              </div>
            </div>
          </div>
          <div class="col-xs-6 wow zoomInRight">
            <div class="card">
              <div class="img-box bg2"></div>
              <div class="book-info book-topic">
                <p>《计算机考研摘星题库》系列</p>
                <p class="book-detail">
                  研芝士教研组精编1500道仿真模拟题，并挑选100所院校经典必考自命题编订，章节设计呼应《精深解读系列》教材，一方面解决了考研学子“无题可做”的痛点，另一方面极大提高了专业课复习练习效率。
                </p>
                <a
                  href="https://detail.tmall.com/item.htm?spm=a1z10.3-b.w4011-24165555638.38.21826772NLnbsB&id=666216386441&rn=977e0c42f86494f7da55bcfd99c2159a&abbucket=15&sku_properties=14829532:72110507"
                  target="_blank"
                  >前往购买 &gt;</a
                >
              </div>
            </div>
          </div>
        </div>
        <div class="row produce">
          <div class="col-xs-6 wow zoomInLeft">
            <div class="card">
              <div class="img-box bg3"></div>
              <div class="book-info book-exam">
                <p>研芝士十年百校真题</p>
                <p class="book-detail">
                  近20年以来的全国2/3院校计算机考研真题完整收录，261所院校真题100%覆盖，拒绝回忆版，并配以全面详尽的答案解析，每道题三审三校，助你准确把握目标院校真题规律！
                </p>
                <a
                  href="http://shop105068382.taobao.com/category-1494631449.htm?spm=a1z10.1-c.w4010-2581427739.14.7be944e57c2xAr&search=y&catName=%C0%FA%C4%EA%D5%E6%CC%E2#bd"
                  target="_blank"
                  >前往购买 &gt;</a
                >
              </div>
            </div>
          </div>
          <div class="col-xs-6 wow zoomInRight">
            <div class="card">
              <div class="img-box bg4"></div>
              <div class="book-info book-exam">
                <p>研芝士模拟四套卷</p>
                <p class="book-detail">
                  年均压中10道题的模拟预测四套卷，基于研芝士教育平台大数据，参考历年真题知识点考察分布和往届考生易错点，由研芝士教研组老师根据数据分析精心挑选和预测，助力学生在考前把握重点快速拔高！
                </p>
                <a
                  href="https://item.taobao.com/item.htm?spm=a1z10.3-c-s.w4002-23454957352.18.63617480ZTQgim&id=634582496637"
                  target="_blank"
                  >前往购买 &gt;</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="course">
      <div class="wrapper">
        <div class="course-left">
          <h2>
            <img
              src="//static.yanzhishi.cn/images/pc/v2.0.0/course_logo.png"
              alt=""
            />
            <p>芝士课程</p>
          </h2>
          <div class="list-item-first">
            <h3>
              <!-- <img src="//static.yanzhishi.cn/images/pc/v2.0.0/course_logo.png" alt=""> -->
              <p>研芝士教育大数据平台</p>
            </h3>
            <p class="list-info">
              大数据、深度学习+名师团队双保障，进行智能组卷，预测新题型，新考法，模拟预测当年考试规律。
            </p>
          </div>
          <div class="list-item">
            <h3>
              <!-- <img src="//static.yanzhishi.cn/images/pc/v2.0.0/course_logo.png" alt=""> -->
              <p>严选教研名师</p>
            </h3>
            <p class="list-info">
              一站式解决重点抓不住，实战没平台，错题没人讲的痛点，带领你一战成硕。
            </p>
          </div>
          <div class="list-item">
            <h3>
              <!-- <img src="//static.yanzhishi.cn/images/pc/v2.0.0/course_logo.png" alt=""> -->
              <p>RDL教学模式</p>
            </h3>
            <p class="list-info">
              基于往届考生在线刷题海量数据之上的名师量化考点讲评和精选习题训练，帮助考生更快定位并突破重点、难点，极大提升备考效率。
            </p>
          </div>
          <div class="list-item">
            <h3>
              <!-- <img src="//static.yanzhishi.cn/images/pc/v2.0.0/course_logo.png" alt=""> -->
              <p>全程督学答疑</p>
            </h3>
            <p class="list-info">
              提供全程的考研备考规划指导、督促完成课程及课后测试并提供专业课的全程名师答疑服务。
            </p>
          </div>
          <!-- <div class="more-box">
            <a class="more" href="//m.cctalk.com/inst/srsv9xeh" target="_blank"
              >查看更多课程 >></a
            >
          </div> -->
        </div>
        <div class="course-right">
          <div class="text-right">
            <div class="course-up">
              <!-- <p class="course-tag"><span>23计算机考研</span></p>
              <h2 class="course-title">研芝士</h2>
              <h3 class="course-title">《练透考点2000题》</h3>
              <p class="info">三层进阶式训练带你由易到难熟练掌握每个考点</p>
              <a href="//www.cctalk.com/m/group/90119413" target="_blank">立即咨询</a> -->
            </div>
          </div>
          <div class="course-list">
            <div class="bg">
              <!-- <p class="tag">全程精选</p>
              <p class="title">408全程班</p>
              <p class="slogan">搞定计算机专业课</p>
              <a href="//www.cctalk.com/m/group/89790282" target="_blank">咨询</a> -->
            </div>
            <div class="bg second">
              <!-- <p class="tag">卷赢训练</p>
              <p class="title">VIP卷赢班</p>
              <p class="slogan">卷赢初试第一道关</p>
              <a href="//www.cctalk.com/m/group/89791691" target="_blank">咨询</a> -->
            </div>
            <div class="bg third">
              <!-- <p class="tag">夯实基础</p>
              <p class="title">C语言先导课</p>
              <p class="slogan">打好算法题基础</p>
              <a href="//www.cctalk.com/m/group/90103369" target="_blank">咨询</a> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="teacher">
      <div class="wrapper">
        <h3 class="bottom-line">优选名师</h3>
        <div class="teacher-container">
          <img
            src="//static.yanzhishi.cn/images/pc/v2.0.0/teacher_2.png"
            alt="教师团队"
          />
          <div class="info">
            <!-- <h3>优选名师</h3> -->
            <p class="detail">7重面试关卡，仅录取5%的优质老师</p>
            <p class="detail">
              主讲老师均毕业于北大、中科院、复旦、北邮、北航等名校，辅助科技赋能，让备考更高效！
            </p>
            <p class="detail">专业化的考核培训，把第8次课留给学生</p>
          </div>
        </div>
        <!-- <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="swiper-item">
                <div class="swiper-item-one"></div>
                <div class="swiper-item-two"></div>
                <div class="swiper-item-three first">
                  <div class="swiper-left">
                    <h2>刘财政<span>首席讲师</span></h2>
                    <p class="teacher-detail">中科院博士，文科跨考计算机，以初试第一名进入复试。依托研芝士题库大数据，创立一个中心、点面结合教学法，具有丰富的计算机考研课程教学经验。</p>
                    <p class="subject-title">擅长科目</p>
                    <p class="subject">组成原理、操作系统</p>
                  </div>
                  <img src="//static.yanzhishi.cn/images/mobile/lcz.png" alt="teacher">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="swiper-item">
                <div class="swiper-item-one"></div>
                <div class="swiper-item-two"></div>
                <div class="swiper-item-three first">
                  <div class="swiper-left">
                    <h2>徐泽汐<span>首席讲师</span></h2>
                    <p class="teacher-detail">国内双一流大学软件工程硕士，研芝士首席讲师，授课风格极具张力，擅长知识点横向拓展与纵向剖析，直击考点！丰富的教学经验更是给学生带来不一样的上课体验，被学生亲切的称为“小汐姐”！</p>
                    <p class="subject-title">擅长科目</p>
                    <p class="subject">数据结构、计算机网络</p>
                  </div>
                  <img src="//static.yanzhishi.cn/images/mobile/xzx.png" alt="teacher">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="swiper-item">
                <div class="swiper-item-one"></div>
                <div class="swiper-item-two"></div>
                <div class="swiper-item-three first">
                  <div class="swiper-left">
                    <h2>李仕聪<span>首席讲师</span></h2>
                    <p class="teacher-detail">北京大学计算机技术硕士，研芝士摘星题库主编，具有多年计算机考研专业课教研经验及百度深度学习目标检测算法研发实战经验，尤其擅长数据结构和操作系统的提分技巧，被学生冠以“最佳程序猿讲师”称号!</p>
                    <p class="subject-title">擅长科目</p>
                    <p class="subject">数据结构、C语言</p>
                  </div>
                  <img src="http://static.yanzhishi.cn/images/mobile/l.png" alt="teacher">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="swiper-item">
                <div class="swiper-item-one"></div>
                <div class="swiper-item-two"></div>
                <div class="swiper-item-three second">
                  <div class="swiper-left">
                    <h2>张云翼<span>首席讲师</span></h2>
                    <p class="teacher-detail">西安交通大学计算机科学与技术工学博士，本硕博均为计算机专业，摘星题库与精编系列编委，有着多年高校一线计算机教学工作经验，擅长计算机网络和操作系统，对两门计算机考研科目具有扎实的理论基础和丰富的授课经验。</p>
                    <p class="subject-title">擅长科目</p>
                    <p class="subject">计算机网络、计算机操作系统</p>
                  </div>
                  <img src="http://static.yanzhishi.cn/images/mobile/z.png" alt="teacher">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="swiper-item">
                <div class="swiper-item-one"></div>
                <div class="swiper-item-two"></div>
                <div class="swiper-item-three three">
                  <div class="swiper-left">
                    <h2>游涛<span>首席讲师</span></h2>
                    <p class="teacher-detail">北京大学计算机科学与技术硕士，研芝士首席真题精讲师，摘星题库编委，具有多年计算机考研专业课教学经验，以及在百度feed流策略算法开发的实战经验，连续多年被学生评选为“年度人气最佳讲师”！</p>
                    <p class="subject-title">擅长科目</p>
                    <p class="subject">数据结构、组成原理</p>
                  </div>
                  <img src="http://static.yanzhishi.cn/images/mobile/you.png" alt="teacher">
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="swiper-item">
                <div class="swiper-item-one"></div>
                <div class="swiper-item-two"></div>
                <div class="swiper-item-three four">
                  <div class="swiper-left">
                    <h2>汤炀<span>人气讲师</span></h2>
                    <p class="teacher-detail">北京大学计算机硕士，研芝士年度人气讲师，具有丰富的计算机专业课讲授经验，课堂风趣幽默，知识点剖析通俗易懂，一线互联网公司开发经验更是给学生的编程能力带来质的提升，所带学员成功上岸北大、北航等双一流院校！</p>
                    <p class="subject-title">擅长科目</p>
                    <p class="subject">数据结构、组成原理</p>
                  </div>
                  <img src="http://static.yanzhishi.cn/images/mobile/t.png" alt="teacher">
                </div>
              </div>
            </div>
          </div>
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
          <div class="swiper-pagination"></div>
        </div> -->
      </div>
      <img
        class="back-img-left"
        src="http://static.yanzhishi.cn/images/pc/v2.0.0/teacher_back_1.png"
        alt="背景"
      />
      <img
        class="back-img-right"
        src="http://static.yanzhishi.cn/images/pc/v2.0.0/teacher_back_2.png"
        alt="背景"
      />
    </div>
    <div class="study">
      <div class="wrapper">
        <h3 class="bottom-line">教学模型</h3>
        <div class="study-content">
          <p>直播<br />讲错题</p>
          <p>在线随课练</p>
          <p>录播讲考点</p>
          <p>推错题报告</p>
          <p>碎片化刷题</p>
          <div class="explain">
            <p>
              老师定时推送智能匹配考点的题目，学过精讲课程后<br />可在研芝士题库Mini
              App上随时练
            </p>
            <p>
              每季度发布一次错题报告，报告数据均来源于<br />历届考生易错题目，基于错题数据的教辅课程<br />更是让学生站在历届学长肩膀上备考
            </p>
            <p>
              芝士平台根据每个考点、每个题目、每个选项三个维度<br />自动抽取所有学员的共性问题，辅助老师直播授课
            </p>
            <p>海量题库提取历年高频考点，<br />每周更新精讲考点的录播课程</p>
            <p>云端实时计算分析百万学生刷题行为<br />自适应匹配最优题目</p>
          </div>
        </div>
      </div>
    </div>
    <div class="services">
      <div class="wrapper">
        <h3 class="bottom-line">我们的服务</h3>
        <div class="row list">
          <div class="col-xs-3">
            <img
              class="last"
              src="http://static.yanzhishi.cn/images/pc/v2.0.0/gongkaike.png"
              alt="辅导"
            />
            <h4>全年免费芝士公开课</h4>
            <p>全体芝士名师，带你把握<br />阶段性复习备考方法与规划</p>
          </div>
          <div class="col-xs-3">
            <img
              src="http://static.yanzhishi.cn/images/pc/v2.0.0/dayi.png"
              alt="答疑"
            />
            <h4>全天候社群答疑</h4>
            <p>助教学姐实时帮你<br />解决考研中的难点、痛点</p>
          </div>
          <div class="col-xs-3">
            <img
              src="http://static.yanzhishi.cn/images/pc/v2.0.0/genzong.png"
              alt="学习"
            />
            <h4>学习效果可跟踪</h4>
            <p>
              数据助力，记录你的学习过程<br />辅导老师一对一跟进，让进步看得见
            </p>
          </div>
          <div class="col-xs-3">
            <img
              src="http://static.yanzhishi.cn/images/pc/v2.0.0/fuwu.png"
              alt="辅导"
            />
            <h4>辅导老师全程服务</h4>
            <p>实时反馈，定期一对一沟通<br />让你的学习指南针永不偏航</p>
          </div>
        </div>
      </div>
      <img
        class="left"
        src="http://static.yanzhishi.cn/images/pc/v2.0.0/bg1.png"
        alt="背景图"
      />
      <img
        class="right"
        src="http://static.yanzhishi.cn/images/pc/v2.0.0/bg3.png"
        alt="背景图"
      />
    </div>
    <div class="promise">
      <div class="wrapper">
        <h3 class="bottom-line">我们的承诺</h3>
        <div class="row promise-detail">
          <div class="col-xs-4">
            <div class="promise-item">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/promise_1.png"
                alt="教得好"
              />
              <div>
                <h3>教学循环</h3>
                <p class="underline back"></p>
                <p>资深名师全程教辅</p>
              </div>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="promise-item promise-special">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/promise_2.png"
                alt="高科技"
              />
              <div>
                <h3>测评准确</h3>
                <p class="underline back"></p>
                <p>最优的数据挖掘模型</p>
              </div>
            </div>
          </div>
          <div class="col-xs-4">
            <div class="promise-item">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/video.png"
                alt="随时可退"
              />
              <div>
                <h3>动态课程</h3>
                <p class="underline back"></p>
                <p>每周优化直播课</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img
        class="left"
        src="http://static.yanzhishi.cn/images/pc/v2.0.0/bg3.png"
        alt="背景图"
      />
      <img
        class="right"
        src="http://static.yanzhishi.cn/images/pc/v2.0.0/bg2.png"
        alt="背景图"
      />
    </div>
    <!-- <div class="commit">
      <div class="wrapper">
        <h3 class="bottom-line">用户的评价</h3>
        <div class="commit-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide">
              <div class="swiper-item">
                <div class="row">
                  <div class="col-xs-4">
                    <div class="card">
                      <div class="title clearfix">
                        <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/wechat.png" alt="avatar">
                        <div class="user">
                          <h3>🐠🐠🐠</h3>
                          <p>广西</p>
                        </div>
                      </div>
                      <p class="star">
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                      </p>
                      <p class="content">感谢研芝士，我前年走了1年的弯路，报的那些机构都没给我辅导好。今年，跟了研芝士，我终于有了回报。虽然分数不高，但对我这个已经工作的学生来说已经很满足了，能上学了。</p>
                      <div class="footer">
                        <p>来源：微信</p>
                        <p>2021.02.27</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4">
                    <div class="card">
                      <div class="title clearfix">
                        <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/weibo.jpg" alt="avatar">
                        <div class="user">
                          <h3>z***y</h3>
                          <p>河南郑州</p>
                        </div>
                      </div>
                      <p class="star">
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                      </p>
                      <p class="content">我的成绩出来啦~我是跨考的学生，在同学的推荐下，了解到了研芝士，跟着百日抢分班课程，考点讲解的很清晰，特别是最后的模拟卷，居然命中了十道题，专业课成绩一下考到了124，真是我没有想到的。</p>
                      <div class="footer">
                        <p>来源：微博</p>
                        <p>2021.02.27</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4">
                    <div class="card">
                      <div class="title clearfix">
                        <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/weibo.jpg" alt="avatar">
                        <div class="user">
                          <h3>东***主</h3>
                          <p>河南三门峡</p>
                        </div>
                      </div>
                      <p class="star">
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                      </p>
                      <p class="content">感谢研芝士一年来的陪伴，从六月份开始知道了研芝士，跟着带学营打卡复习，八月份开始用小程序刷题，跟着课程做课后题，所有的习题刷了两遍，现在终于有了回报！太感谢了~</p>
                      <div class="footer">
                        <p>来源：微博</p>
                        <p>2021.02.28</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="swiper-item">
                <div class="row">
                  <div class="col-xs-4">
                    <div class="card">
                      <div class="title clearfix">
                        <img src="http://static.yanzhishi.cn/images/wechat/tb_avatar.jpeg" alt="avatar">
                        <div class="user">
                          <h3>欧***菜</h3>
                        </div>
                      </div>
                      <p class="star">
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                      </p>
                      <p class="content">🌈质量很好，买的很合我心意💕，棒棒哒！✨需要的亲们可以放心购买🌵值得购买！没有不舒服的地方😶，👍产品很快就收到了，比想象中还好，不错不错！希望能耐用包装好，物流快，价格实惠，比超市便宜多了包装很好，颜值不错，质量也没有问题。</p>
                      <div class="footer">
                        <p>来源：淘宝</p>
                        <p>2020.12.02</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4">
                    <div class="card">
                      <div class="title clearfix">
                        <img src="http://static.yanzhishi.cn/images/wechat/tb_avatar.jpeg" alt="avatar">
                        <div class="user">
                          <h3>爱***g</h3>
                          <p>浙江金华</p>
                        </div>
                      </div>
                      <p class="star">
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                      </p>
                      <p class="content">包装很用心，收到书本没有磕碰，完好无损；内容很全很详细，习题难度适中，还有很多真题，很适合刷题；纸质光滑印刷清晰，性价比超高，还有微信刷题小程序配合使用。</p>
                      <div class="footer">
                        <p>来源：淘宝</p>
                        <p>2020.8.31</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4">
                    <div class="card">
                      <div class="title clearfix">
                        <img src="http://static.yanzhishi.cn/images/wechat/tb_avatar.jpeg" alt="avatar">
                        <div class="user">
                          <h3>李***套</h3>
                          <p>河南南阳</p>
                        </div>
                      </div>
                      <p class="star">
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                      </p>
                      <p class="content">这个是跟精神解读一套的材料，当时看到有教材还有配套题库，觉得还不错，就一起拍下来了。跟教材的纸质一样，听客服小姐姐说，这个配合精神解读用起来效果更好，期待中。</p>
                      <div class="footer">
                        <p>来源：淘宝</p>
                        <p>2020.3.30</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="swiper-slide">
              <div class="swiper-item">
                <div class="row">
                  <div class="col-xs-4">
                    <div class="card">
                      <div class="title clearfix">
                        <img src="http://static.yanzhishi.cn/images/wechat/tb_avatar.jpeg" alt="avatar">
                        <div class="user">
                          <h3>x***丶</h3>
                          <p>河南郑州</p>
                        </div>
                      </div>
                      <p class="star">
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                      </p>
                      <p class="content">内容阅读感受：有模拟题有真题，还有解析，很好 色彩情况：无色差，适合阅读 印刷质量：印刷材料很精美 纸张品质：纸质摸起来是那种光滑的，比一般的书纸质要好。</p>
                      <div class="footer">
                        <p>来源：淘宝</p>
                        <p>2020.8.04</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4">
                    <div class="card">
                      <div class="title clearfix">
                        <img src="http://static.yanzhishi.cn/images/wechat/tb_avatar.jpeg" alt="avatar">
                        <div class="user">
                          <h3>潜***9</h3>
                          <p>河南信阳</p>
                        </div>
                      </div>
                      <p class="star">
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                      </p>
                      <p class="content">书的质量很好，很满意，客服态度超好。书的内容也挺不错，全是各大院校真题，正需要这种刷题的习题册。现在考研才刚开始准备，专业课也才开始，买这个属于有备无患，希望自己能顺利上岸吧……</p>
                      <div class="footer">
                        <p>来源：淘宝</p>
                        <p>2020.6.29</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-4">
                    <div class="card">
                      <div class="title clearfix">
                        <img src="http://static.yanzhishi.cn/images/wechat/tb_avatar.jpeg" alt="avatar">
                        <div class="user">
                          <h3>傻***潴</h3>
                          <p>河南洛阳</p>
                        </div>
                      </div>
                      <p class="star">
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                        <i class="glyphicon glyphicon-star"></i>
                      </p>
                      <p class="content">包装非常好，台风天也不怕书本进水，书本的印刷质量很棒，清晰可见，条理分明。比起王d的动辄几百块要价格上要亲民多了，王d的4本要160多吧，翻了个倍，质量和内容我觉得研芝士的更胜一筹。</p>
                      <div class="footer">
                        <p>来源：淘宝</p>
                        <p>2020.08.08</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div id="aboutUs" class="brand">
      <div class="wrapper">
        <h3 class="black-title">高等教育质选品牌</h3>
        <div class="info">
          <p>
            研芝士 | IT类工科上岸模型开创者。由北京大学，北京邮电大学，中国人民大学，中科院、北航多名研究生联合创办于2019年。
          </p>
          <p>
            截止目前获得机构投资人两轮投资，估值超过七千万。
          </p>
          <p>
            数据驱动教育—研芝士通过真题、模拟题、高频考点、效果跟踪、云端计算、实时分析、数据反馈，结合40多名专业讲师和督学服务，帮助更多双非高校学生享受普惠、优质的教育资源，通过关键大考，塑就幸福人生！
          </p>
        </div>
      </div>
    </div>
    <div class="friend">
      <div class="wrapper">
        <h3 class="bottom-line">合作伙伴</h3>
        <div class="row friend-content">
          <div class="col-xs-me-5">
            <div class="friend-img">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_1.jpg"
                alt="伙伴"
              />
            </div>
          </div>
          <div class="col-xs-me-5">
            <div class="friend-img">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_2.png"
                alt="伙伴"
              />
            </div>
          </div>
          <!-- <div class="col-xs-me-5">
            <div class="friend-img">
              <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_3.jpg" alt="伙伴">
            </div>
          </div> -->
          <div class="col-xs-me-5">
            <div class="friend-img">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_4.png"
                alt="伙伴"
              />
            </div>
          </div>
          <div class="col-xs-me-5">
            <div class="friend-img">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_5.png"
                alt="伙伴"
              />
            </div>
          </div>
          <div class="col-xs-me-5">
            <div class="friend-img">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_6.png"
                alt="伙伴"
              />
            </div>
          </div>
          <div class="col-xs-me-5">
            <div class="friend-img">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_7.jpg"
                alt="伙伴"
              />
            </div>
          </div>
          <!-- <div class="col-xs-me-5">
            <div class="friend-img">
              <img src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_8.jpg" alt="伙伴">
            </div>
          </div> -->
          <div class="col-xs-me-5">
            <div class="friend-img">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_9.png"
                alt="伙伴"
              />
            </div>
          </div>
          <div class="col-xs-me-5">
            <div class="friend-img">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_10.png"
                alt="伙伴"
              />
            </div>
          </div>
          <div class="col-xs-me-5">
            <div class="friend-img">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_11.png"
                alt="伙伴"
              />
            </div>
          </div>
          <div class="col-xs-me-5">
            <div class="friend-img">
              <img
                src="http://static.yanzhishi.cn/images/pc/v2.0.0/friend_12.png"
                alt="伙伴"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <a class="qq-contact" target="_blank" href="https://qm.qq.com/cgi-bin/qm/qr?k=cKKkshs5TK17NrD7aO8bpTfH2JAnwfQS&jump_from=webapi"><img src="http://static.yanzhishi.cn/images/pc/v2.0.0/qq.png" alt="22计算机考研"></a> -->
    <div class="contact">
      <div class="contact__title">
        <span class="line"></span>
        <span class="contact__title-label">加盟合作</span>
        <span class="line"></span>
      </div>
      <div class="contact__content">
        <div class="contact__content-item">
          <img src="http://static.yanzhishi.cn/images/pc/2023/phone_icon.jpg" alt="phone">
          <span>153 0382 4340</span>
        </div>
        <div class="contact__content-item">
          <img src="http://static.yanzhishi.cn/images/pc/2023/user_icon.jpg" alt="phone">
          <p><span>李老师</span><span class="text-label">（微信同号）</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue";

const initPage = () => {
  const nowDay = new Date().getFullYear();
  $(".year").text(nowDay);

  new WOW().init();

  const swiper = new Swiper(".swiper-container", {
    speed: 800,
    autoplay: {
      delay: 3000,
    },
    loop: true,
    pagination: {
      el: ".swiper-pagination",
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
  });

  new Swiper(".commit-container", {
    speed: 2000,
    autoplay: {
      delay: 4000,
    },
    loop: true,
  });

  $(".practice").hover(
    () => {
      $(".qrcode-img").show();
    },
    () => {
      $(".qrcode-img").hide();
    }
  );
};
onMounted(() => {
  initPage();
});
</script>

<style lang="less" scoped>
@import "../../assets/css/animate.css";
// @import "../../assets/css/bootstrap.min.css";
@import "../../assets/css/swiper-bundle.css";
@import "../../css/less/index.less";
// @import "../../css/less/mobile.less";
// .top {
//   text-align: left;
// }
.study-content {
  > p {
    margin: 0 0 10px !important;
  }
}
.introduce {
  p {
    text-align: left;
  }
}
.item-one {
  left: 0;
}
.friend {
  border-bottom: 1px solid #f1f1f1;
}
.contact {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 180px;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.2);
  background: #fff;
  border-radius: 8px;
  overflow: hidden;
  &__title {
    background: #ffc300;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 800;
    .line {
      width: 14px;
      height: 1px;
      background: #fff;
    }
    &-label {
      margin: 0 4px;
    }
  }
  &__content {
    padding: 5px 0 10px 0;
    &-item {
      padding-left: 10px;
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
      span {
        font-weight: 800;
      }
      .text-label {
        font-size: 12px;
        color: #999;
      }
    }
  }
}
.teacher-container {
  > img {
    width: 100%;
  }
  .info {
    margin: 0 !important;
  }
}
</style>
