<template>
  <div class="footer">
    <div class="footer-item">地址：北京市海淀区天创科技大厦7层711A | 河南省郑州市高新区升龙又一城B座7层369室</div>
    <div class="footer-item">
      <span>起立网络传媒技术（北京）有限公司</span>
      <span class="ml-space">Copyright ©{{ new Date().getFullYear() }}</span>
      <a
        href="https://beian.miit.gov.cn/"
        style="color: #555; margin-left: 12px"
        target="view_window"
        >京ICP备19021908-2号</a
      >
      <a
        target="_blank"
        href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802041403"
        style="
          display: inline-block;
          text-decoration: none;
          height: 20px;
          line-height: 20px;
          margin-left: 12px;
        "
      >
        <img
          src="http://static.yanzhishi.cn/images/pc/police.jpeg"
          style="float: left"
        />
        <p
          style="
            float: left;
            height: 20px;
            line-height: 20px;
            margin: 0px 0px 0px 5px;
            color: #555;
          "
        >
          京公网安备 11010802041403号
        </p>
      </a>
    </div>
  </div>
</template>

<script setup></script>

<style lang="less" scoped>
.footer {
  padding: 20px 0;
  flex-shrink: 0;
  &-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.ml-space {
  margin-left: 10px;
}
</style>
